body {
  font-family: sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.nav-scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.nav-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 3px;
}

/* Handle */
.nav-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  width: 20px;
}

/* Handle on hover */
.nav-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 400px;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
.zoom-in-image {
  animation: ZoomIn 3s;
}

@keyframes ZoomIn {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 110% 110%;
  }
}

.zoom-in-image1 {
  animation: ZoomIn1 3.1s;
}

@keyframes ZoomIn1 {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 110% 110%;
  }
}
.fade-in-image {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-image1 {
  animation: fadeIn1 3.1s;
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.aboutBgImg {
  background-image: url("../public/images/page-bg-img-4.png");
}
.footerBgImg {
  background-image: linear-gradient(45deg, black, #000000e6),
    url("../public/images/footer-bg.jpg");
  background-size: cover;
  background-position: center;
}

.contactBgImg {
  background-image: linear-gradient(45deg, black, #000000e6),
    url("../public/images/contact-bn.jpg");
  background-size: cover;
  background-position: center;
}

.img-flx img {
  height: 400px;
  object-fit: cover;
  max-width: 100%;
  min-width: 100%;
  vertical-align: bottom;
}

.divgridtm {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 0.81fr 1fr 0.81fr 1fr;
}

/* blog css */
@import url("https://fonts.googleapis.com/css?family=Oswald:300|Roboto:300&display=swap");
:root {
  --master-color: #c19f63;
  --master-color-partner-dark: #7c591d;

  --headings-color: #263358;

  --main-bg-color: #fff;
  --secondary1-bg-color: #f6f6f6;
  --secondary2-bg-color: #fff0ec;

  --main-txt-color: #333;
  --secondary-txt-color: #757575;

  --sub-color: #b4b4b4;
}
main {
  min-height: 100vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.5em;
}

/**
 * Post List Layout
 */
.post-list_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
}

.post-list__post {
  display: grid;
  grid-template-areas:
    "header"
    "footer"
    "pr_img";
  grid-auto-rows: 1fr auto auto;
  align-items: start;
  grid-gap: 1em 0;
}

.post-list__post:nth-child(2n + 1) {
  background-color: var(--secondary1-bg-color);
}

.post-list__post:nth-child(3n + 2) {
  grid-template-areas:
    "pr_img"
    "header"
    "footer";
  grid-auto-rows: auto 1fr auto;
}

.post__preview-img {
  grid-area: pr_img;
}

.post__header {
  grid-area: header;
}

.post__meta {
  grid-area: footer;
  align-self: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/**
 * Post List Design
 */
.post-list {
  margin-bottom: 4em;
}

.post {
  position: relative;
  padding: 1.6em;
}
.post-list__post:nth-child(even) {
  background-color: rgb(238, 238, 238);
}
.post:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px var(--master-color) solid;
  border-image: linear-gradient(
      110deg,
      #c19f63,
      #c6972b,
      #f5cd87,
      #c6972b,
      #c19f63
    )
    1;
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.post:hover:after {
  opacity: 1;
}

.post > * {
  z-index: 4;
  position: relative;
}

.post__preview-img {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.post__preview-img img {
  width: 100%;
  height: auto;

  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  margin: auto;
}

.post__time {
  color: var(--sub-color);
}

.post__meta a {
  color: var(--sub-color);
  text-decoration: none;
  border-bottom: 1px var(--sub-color) solid;
}

.post__meta a:hover {
  color: var(--master-color-partner-dark);
}

.post__comments {
  position: relative;
  padding-left: 24px;
}

.post__comments:before,
.post__comments:after {
  content: "";
  display: block;
  position: absolute;
  border: 1px var(--sub-color) solid;
}

.post__comments:before {
  width: 16px;
  height: 11px;
  border-radius: 50%;
  left: 0;
  top: calc(50% - 8px);
  z-index: 1;
}

.post__comments:after {
  width: 3px;
  height: 6px;
  border-width: 0 1px 1px 0;
  transform: rotate(31deg);
  top: 50%;
  left: 11px;
  z-index: 2;
  background-color: var(--secondary1-bg-color);
}
.post__header h2 {
  font-size: calc(22px + 0.8vw);
}
.post__header h1,
.post__header h2 {
  display: block;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4em;
  color: var(--headings-color);
}

.post__header h1 {
  font-size: calc(24px + 2vw);
  margin: 1.2em 0;
}

h2 {
  font-size: calc(22px + 0.8vw);
}

.post__header h1 a,
.post__header h2 a {
  font-weight: 300;
  text-decoration: none;
  background-size: 0;
  color: var(--headings-color);
  background-color: transparent;
  outline: none;
}

.post__header a:hover {
  color: var(--master-color-partner-dark);
}

.post__header a:focus {
  color: var(--headings-color);
  background-color: var(--secondary2-bg-color);
}

/**
 * Breakpoint 900px
 */
@media all and (max-width: 900px) {
  .post-list_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .post-list__post:nth-child(n) {
    background-color: transparent;
    grid-template-areas:
      "header"
      "footer"
      "pr_img";
    grid-auto-rows: 1fr auto auto;
  }

  .post-list__post:nth-child(4n),
  .post-list__post:nth-child(4n + 1) {
    background-color: var(--secondary1-bg-color);
  }

  .post-list__post:nth-child(even) {
    grid-template-areas:
      "pr_img"
      "header"
      "footer";
    grid-auto-rows: auto 1fr auto;
  }
}

/**
 * Breakpoint 670px
 */
@media all and (max-width: 670px) {
  .post-list_grid {
    grid-template-columns: 1fr;
  }

  .post-list__post:nth-child(n) {
    background-color: var(--secondary1-bg-color);
    grid-template-areas:
      "header"
      "footer"
      "pr_img";
    grid-auto-rows: 1fr auto auto;
  }

  .post-list__post:nth-child(even) {
    background-color: transparent;
  }
}

/* Chrome, Edge, and Safari */
.scrollBar::-webkit-scrollbar {
  width: 5px;
  max-height: 50%;
  height: 50%;
}

.scrollBar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.scrollBar::-webkit-scrollbar-thumb {
  background-color: #0000001f;
  border-radius: 14px;
  border: 3px solid transparent;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
